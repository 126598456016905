import React, { FC, ReactNode } from 'react'

import LayoutLibrary from '@flyvet/flyvet-react-library/dist/src/containers/layouts/layout-default'

import '../styles/variables.scss'

import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'

export type LayoutProps = {
  children: ReactNode
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      company: companyJson {
        name
        slogan
        address {
          cidade
        }
      }
      logoHorizontal: file(name: { eq: "logo-horizontal" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      partner1: file(name: { eq: "partner-1" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      partner2: file(name: { eq: "partner-2" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      partner3: file(name: { eq: "partner-3" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `)

  const company = data.company
  const logoHorizontal = getImage(
    data.logoHorizontal.childImageSharp.gatsbyImageData
  )!
  const partner1 = getImage(data.partner1.childImageSharp.gatsbyImageData)!
  const partner2 = getImage(data.partner2.childImageSharp.gatsbyImageData)!
  const partner3 = getImage(data.partner3.childImageSharp.gatsbyImageData)!

  const getGatsbyImageNode = (
    image: IGatsbyImageData,
    alt: string,
    isContained?: boolean
  ) =>
    (
      <GatsbyImage
        image={image}
        alt={alt}
        loading="eager"
        style={{ width: '100%', height: '100%' }}
        objectFit={isContained ? 'contain' : 'cover'}
      />
    ) as ReactNode

  return (
    <LayoutLibrary
      logoFooter={getGatsbyImageNode(logoHorizontal, `Logo ${company}`, true)}
      logoTopbar={getGatsbyImageNode(logoHorizontal, `Logo ${company}`, true)}
      companyName={company.name}
      city={company.address.cidade}
      slogan={company.slogan}
      hasSloganFooter={false}
      badge={{ type: 'digital' }}
      navItems={[
        {
          label: 'Home',
          path: '/'
        },
        {
          label: 'Serviços',
          path: '/servicos'
        },
        {
          label: 'Galeria',
          path: '/galeria'
        },
        {
          label: 'Contato',
          path: '/contato'
        }
      ]}
      facebook="Comptest"
      instagram="comptest_eng"
      linkedin="company/comptest"
      socialsOnBox
      partnerLogos={[
        getGatsbyImageNode(partner1, 'Parceiro 1', true),
        getGatsbyImageNode(partner2, 'Parceiro 2', true),
        getGatsbyImageNode(partner3, 'Parceiro 3', true)
      ]}
    >
      {children}
    </LayoutLibrary>
  )
}

export default Layout
